<template>
  <div class="add-template-type">
    <el-dialog
      :title="$t('templateInfo.Create Template Type')"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form
        ref="form"
        v-loading="loading"
        :model="form"
        label-width="130px"
        size="small"
      >
        <el-form-item :label="$t('templateInfo.Template Type')">
          <el-input
            v-model="form.templateTypeName"
            maxlength="100"
            :placeholder="$t('templateInfo.Template Type')"
          />
        </el-form-item>
        <el-form-item>
          <el-button @click="dialogVisible = false">
            {{$t('button.Cancel')}}
          </el-button>
          <el-button
            type="success"
            @click="onSubmit"
          >
            {{$t('button.Save')}}
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>

import { createTemplateType, updateTemplateType } from '@/libs/api/templateType'

import {
  parseResp,
  parseError,
} from '@/views/taskManagement/taskManger/utils/requestUtil'

import modelUi from '@/views/taskManagement/taskManger/utils/modelUi'

export default {
  data() {
    return {
      dialogVisible: false,
      loading: false,
      sceneList: [],
      templateTypeId: '',
      form: {},
    }
  },
  methods: {
    show(templateTypeId, templateTypeName,storeId,scenarioId) {
      this.templateTypeId = templateTypeId
      this.form = {
        templateTypeName,
        storeId,
        scenarioId
      }
      this.dialogVisible = true
    },
    async onSubmit() {
      if (!this.form.templateTypeName) {
        modelUi.msgError('Input name')
        return
      }

      this.loading = true
      if (this.templateTypeId) {
        updateTemplateType(this.templateTypeId, this.form.templateTypeName).then(res => {
          this.loading = false
          const result = parseResp(res)
          if (result.success) {
            this.$message({
              message: this.$t('commonMessage.ModifySucess'),
              type: 'success',
            })
            this.$emit('getTypeList')
            this.dialogVisible = false
          } else {
            modelUi.msgError(result.message)
          }
        }).catch(err => {
          this.loading = false
          modelUi.msgError(parseError(err))
        })
      } else {
        let storeId = this.form.storeId
        let scenarioId = this.form.scenarioId
        let params ={
          templateTypeName:this.form.templateTypeName,
          storeId,
          scenarioId
        }
        createTemplateType(params).then(res => {
          this.loading = false
          const result = parseResp(res)
          if (result.success) {
            this.$message({
              message: this.$t('commonMessage.CreateSucess'),
              type: 'success',
            })
            this.$emit('getTypeList')
            this.dialogVisible = false
          } else {
            modelUi.msgError(result.message)
          }
        }).catch(err => {
          this.loading = false
          modelUi.msgError(parseError(err))
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.add-template-type{
  ::v-deep .el-button{
    width: 110px;
  }
}
</style>
