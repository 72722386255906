<template>
  <div class="create-template">
    <el-dialog
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      :before-close="cancel"
      :close-on-click-modal="false"
      width="600px"
      :title="
        form.templateId
          ? $t('templateInfo.Edit Template')
          : $t('templateInfo.New Template')
      "
    >
      <el-form
        ref="form"
        v-loading="loading"
        :model="form"
        :rules="rules"
        label-width="135px"
        size="small"
      >
        <el-form-item :label="$t('templateInfo.Scenarios')" prop="scenarioId">
          <el-select
            v-model="form.scenarioId"
            :placeholder="
              $t('layout.Select') + ' ' + $t('templateInfo.Scenarios')
            "
            :disabled="!isTemplateAdd"
            @change="scenarioIdChange"
          >
            <el-option
              v-for="item in sceneList"
              :key="item.scenarioId"
              :label="item.translateTextName"
              :value="item.scenarioId"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="userData.userType ===203"
          :label="$t('itemsScenarios.ViewDialog.VisibleRange')"
          required
        >
          <el-radio-group v-model="form.openType">
            <el-radio :label="0">
              {{ $t("itemsScenarios.ViewDialog.ThisStore") }}
            </el-radio>
            <el-radio :label="1">
              {{ $t("itemsScenarios.ViewDialog.AllStore") }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="isTemplateAdd"
          :label="$t('templateInfo.Template Size')"
          required
        >
          <el-radio-group v-model="form.templateSize" @change="changeType">
            <el-radio :label="1">
              {{ $t("templateInfo.ESL Size") }}
            </el-radio>
            <el-radio :label="2">
              {{ $t("templateInfo.Layout Block") }}
            </el-radio>
            <el-radio :label="3">
              {{ $t("templateInfo.Custom") }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="form.templateSize == 1 && isTemplateAdd"
          :label="$t('templateInfo.ESL Size')"
          prop="eslsize"
        >
          <el-select
            v-model="form.eslsize"
            :placeholder="
              $t('layout.Select') + ' ' + $t('templateInfo.ESL Size')
            "
            @change="changeEslSize"
          >
            <el-option
              v-for="item in tagList"
              :key="item.tagTypeCodeId"
              :label="item.typeName"
              :value="item.tagTypeCodeId"
            />
          </el-select>
        </el-form-item>
        <template v-if="form.templateSize == 2">
          <el-form-item :label="$t('templateInfo.Group Name')" prop="groupName">
            <el-select
              v-model="form.groupName"
              :placeholder="
                $t('layout.Select') + ' ' + $t('templateInfo.Group Name')
              "
              @change="groupNameChange"
              @visible-change="groupNameVisibleChange"
            >
              <el-option
                v-for="item in groupNameList"
                :key="item.layoutGroupId"
                :label="item.layoutGroupName"
                :value="item.layoutGroupId"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('templateInfo.Layout Strategy')"
            prop="Strategy"
          >
            <el-select
              v-model="form.Strategy"
              :placeholder="
                $t('layout.Select') + ' ' + $t('templateInfo.Layout Strategy')
              "
              @change="strategyChange"
            >
              <el-option
                v-for="item in strategyList"
                :key="item.layoutStrategyId"
                :label="item.layoutStrategyName"
                :value="item.layoutStrategyId"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('templateInfo.Layout')" prop="layout">
            <el-select
              v-model="form.layout"
              :placeholder="
                $t('layout.Select') + ' ' + $t('templateInfo.Layout')
              "
              @change="layoutChange"
            >
              <el-option
                v-for="item in layoutList"
                :key="item.layoutId"
                :label="item.layoutName"
                :value="item.layoutId"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('templateInfo.Layout Block')"
            prop="layoutBlock"
          >
            <el-select
              v-model="form.layoutBlock"
              :placeholder="
                $t('layout.Select') + ' ' + $t('templateInfo.Layout Block')
              "
              @change="layoutBlockChange"
            >
              <el-option
                v-for="item in layoutBlockList"
                :key="item.layoutLumpId"
                :label="$t('layout.Layout Block') + ' ' + item.sort"
                :value="item.layoutLumpId"
              />
            </el-select>
          </el-form-item>
        </template>
        <el-form-item :label="$t('templateInfo.Canvas Width')" prop="width">
          <el-input
            v-model.number="form.width"
            maxlength="5"
            :disabled="
              form.templateSize == 1 || form.templateSize == 2 || !isTemplateAdd
            "
            clearable
          />
        </el-form-item>
        <el-form-item :label="$t('templateInfo.Canvas Height')" prop="height">
          <el-input
            v-model.number="form.height"
            maxlength="5"
            :disabled="
              form.templateSize == 1 || form.templateSize == 2 || !isTemplateAdd
            "
            clearable
          />
        </el-form-item>
        <el-form-item :label="$t('templateInfo.Canvas Color')" prop="color">
          <el-select v-model="form.color" :disabled="!isTemplateAdd">
            <el-option
              v-for="item in colorList"
              :key="item.codeId"
              :label="item.codeName"
              :value="item.codeId"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('templateInfo.Template Type')">
          <div class="d-flex">
            <el-select
              v-model="form.templateTypeId"
              :placeholder="
                $t('layout.Select') + ' ' + $t('templateInfo.Template Type')
              "
              :disabled="!form.scenarioId"
              clearable
            >
              <el-option
                v-for="item in typeList"
                :key="item.templateTypeId"
                :label="item.templateTypeName"
                :value="item.templateTypeId"
              >
                <div class="edit-template-type-item">
                  <div class="edit-template-type-name">
                    {{ item.templateTypeName }}
                  </div>

                  <div v-if="item.openType !== 3">
                    <span
                      class="el-icon-edit-outline"
                      @click.stop="handleTemplateTypeEdit(item)"
                    />
                    <span
                      class="el-icon-delete"
                      @click.stop="handleTemplateTypeDel(item)"
                    />
                  </div>
                </div>
              </el-option>
            </el-select>
            <el-button
              type="primary"
              icon="el-icon-plus"
              style="margin-left: 5px"
              :disabled="!form.scenarioId"
              @click="handleTemplaetTypeAdd"
            />
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('templateInfo.Template Name')"
          prop="templateName"
        >
          <el-input
            v-model="form.templateName"
            :placeholder="$t('templateInfo.Template Name')"
            maxlength="100"
            clearable
          />
        </el-form-item>
        <el-form-item :label="$t('templateInfo.Template Code')">
          <el-input
            v-model="form.templateCode"
            :placeholder="$t('templateInfo.Template Code')"
            maxlength="100"
            clearable
          />
        </el-form-item>

        <!-- <el-form-item>
          <el-button type="primary" @click="onSubmit">Save</el-button>
          <el-button>Cancel</el-button>
        </el-form-item>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="small">
          {{ $t("button.Cancel") }}
        </el-button>
        <el-button type="success" size="small" @click="onSubmit('form')">
          {{ $t("button.Save") }}
        </el-button>
      </div>
    </el-dialog>
    <addTemplateType
      ref="addTemplateType"
      @getTypeList="getTemplateTypeListReq"
    />
  </div>
</template>

<script>
import addTemplateType from "./addTemplateType.vue";

import {
  getStoreId,
  getMerchantId,
} from "@/views/taskManagement/taskManger/utils/cacheUtil.js";

import {
  parseResp,
  parseError,
} from "@/views/taskManagement/taskManger/utils/requestUtil";
import { getCodeList } from "@/libs/api/taskManger.js";

import modelUi from "@/views/taskManagement/taskManger/utils/modelUi";

import {
  createTemplate,
  updateTemplate,
  getAllTagType,
} from "@/libs/api/taskManger";

import {
  deleteTemplateType,
  getTemplateTypeList,
} from "@/libs/api/templateType";

export default {
  components: {
    addTemplateType,
  },
  data() {
    return {
      userData:JSON.parse(localStorage.getItem('userData')),
      dialogVisible: false,
      isTemplateAdd: true,
      loading: false,
      dialogTitle: "",
      sceneList: [],
      typeList: [],
      tagList: [],
      groupNameList: [],
      strategyList: [],
      layoutList: [],
      layoutBlockList: [],
      colorList: [],
      form: {
        groupName: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        templateCode: "",
        templateTypeId: "",
        scenarioId: "",
        templateSize: 1,
        openType:0
      },
      rules: {
        scenarioId: {
          required: true,
          message: this.$t("formValidate.required fields"),
          trigger: "change",
        },
        eslsize: {
          required: true,
          message: this.$t("formValidate.required fields"),
          trigger: "change",
        },
        groupName: {
          required: true,
          message: this.$t("formValidate.required fields"),
          trigger: "change",
        },
        Strategy: {
          required: true,
          message: this.$t("formValidate.required fields"),
          trigger: "change",
        },
        layout: {
          required: true,
          message: this.$t("formValidate.required fields"),
          trigger: "change",
        },
        layoutBlock: {
          required: true,
          message: this.$t("formValidate.required fields"),
          trigger: "change",
        },
        width: [
          {
            required: true,
            message: this.$t("formValidate.required fields"),
            trigger: "blur",
          },
          { type: "number", message: this.$t('templateInfo.ErrorMessage2') },
          { validator: this.checkWidth, trigger: 'blur' }
        ],
        height: [
          {
            required: true,
            message: this.$t("formValidate.required fields"),
            trigger: "blur",
          },
          { type: "number", message: this.$t('templateInfo.ErrorMessage2') },
          { validator: this.checkWidth, trigger: 'blur' }
        ],
        color: [
          {
            required: true,
            message: this.$t("formValidate.required fields"),
            trigger: "change",
          },
        ],
        templateName: [
          {
            required: true,
            message: this.$t("formValidate.required fields"),
            trigger: "blur",
          },
        ],
        templateCode: [
          {
            required: true,
            message: this.$t("formValidate.required fields"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 取消按钮
    cancel() {
      (this.form = {
        groupName: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        templateCode: "",
        templateTypeId: "",
        scenarioId: "",
        templateSize: 1,
        openType:0
      }),
        (this.isTemplateAdd = true),
        (this.dialogVisible = false);
    },
    show(row) {
      this.dialogVisible = true;
      if (row && row.templateId) {
        this.isTemplateAdd = false;
        this.form = row;
      } else if(row && row.width){
        // 布局编辑--绑定模板弹窗种的创建--跳转过来的
        let data ={
          ...this.form,
          ...row
        }
        this.form = data;
        this.getTemplateTypeListReq();
      }
      if (row && row.templateId) {
        this.dialogTitle = "Edit Template";
        this.getTemplateTypeListReq();
      } else {
        this.dialogTitle = "New Template";
      }

      this.getScenarioList();
      this.changeType();
      this.getAllTagTypeReq();
      this.getColorListReq();
    },
    // 校验---宽和高的值
    checkWidth(rule, value, callback){
      console.log(value)
      if(value === undefined){
        callback(new Error(this.$t('formValidate.required fields')));
      }
      if (value <= 0) {
        callback(new Error(this.$t('templateInfo.ErrorMessage3')));
      } else {
        callback();
      }
    },

    scenarioIdChange() {
      this.$set(this.form, "groupName", "");
      this.$set(this.form, "Strategy", "");
      this.$set(this.form, "layout", "");
      this.$set(this.form, "layoutBlock", "");
      this.groupNameList = [];
      this.strategyList = [];
      this.layoutList = [];
      this.layoutBlockList = [];
      let form ={
        ...this.form,
        templateTypeId:''
      }
      this.form = form
      this.getTemplateTypeListReq()
    },
    groupNameChange(value) {
      const item = this.groupNameList.find((it) => it.layoutGroupId === value);
      this.strategyList = item.children || [];
      this.layoutList = [];
      this.layoutBlockList = [];
      this.$set(this.form, "Strategy", "");
      this.$set(this.form, "layout", "");
      this.$set(this.form, "layoutBlock", "");
    },
    groupNameVisibleChange(val) {
      if (val && this.groupNameList.length === 0) {
        this.getGroupNameListReq();
      }
    },
    strategyChange(value) {
      const item = this.strategyList.find(
        (it) => it.layoutStrategyId === value
      );
      this.layoutList = item.children || [];
      this.layoutBlockList = [];
      this.$set(this.form, "layout", "");
      this.$set(this.form, "layoutBlock", "");
    },
    layoutChange(value) {
      const item = this.layoutList.find((it) => it.layoutId === value);
      this.layoutBlockList = item.children || [];
      this.$set(this.form, "layoutBlock", "");
    },
    changeType() {
      if (this.form.templateSize === 1) {
        this.getAllTagTypeReq();
      } else if (this.form.templateSize === 2) {
        this.getGroupNameListReq();
      }
    },
    layoutBlockChange(value) {
      const item = this.layoutBlockList.find((it) => it.layoutLumpId === value);
      this.$set(this.form, "width", item.lumpWidth);
      this.$set(this.form, "height", item.lumpHeight);
    },

    changeEslSize(value) {
      const item = this.tagList.find((it) => it.tagTypeCodeId === value);
      this.$set(this.form, "width", item.typeWidth);
      this.$set(this.form, "height", item.typeHeight);
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.form.templateId) {
            const {
              templateName,
              templateTypeId,
              width,
              height,
              templateCode,
              scenarioId,
              color,
              openType
            } = this.form;

            this.loading = true;
            createTemplate({
              templateName,
              templateTypeId,
              width,
              height,
              templateCode,
              scenarioId,
              color,
              openType,
              storeId: localStorage.getItem("storeId"),
            })
              .then((res) => {
                this.loading = false;
                const result = parseResp(res);
                if (result.success) {
                  this.cancel();
                  this.$message({
                    message: this.$t("commonMessage.DeleteSucess"),
                    type: "success",
                  });
                  this.$emit("add", result.data, scenarioId);
                } else {
                  this.$message({
                    message: result.message,
                    type: "error",
                    customClass: "messageIndex",
                  });
                }
              })
              .catch((err) => {
                this.loading = false;
                this.$message({
                  message: err.message,
                  type: "error",
                  customClass: "messageIndex",
                });
              });
          } else {
            const { templateId, templateName, templateTypeId, templateCode,openType } =
              this.form;
            updateTemplate({
              templateId,
              templateName,
              templateTypeId,
              templateCode,
              openType
            })
              .then((res) => {
                this.loading = false;
                const result = parseResp(res);
                if (result.success) {
                  this.cancel();
                  this.$message({
                    message: this.$t("commonMessage.ModifySucess"),
                    type: "success",
                  });
                  this.$emit("update");
                } else {
                  modelUi.msgError(result.message);
                }
              })
              .catch((err) => {
                this.loading = false;
                modelUi.msgError(parseError(err));
              });
          }
        } else {
          console.log("error submit!!");
          console.log(this.form);
          return false;
        }
      });
    },
    getAllTagTypeReq() {
      const merchantId = getMerchantId();
      getAllTagType(merchantId, "1")
        .then((res) => {
          const result = parseResp(res);
          if (result.success) {
            this.tagList = result.data;
          } else {
            modelUi.msgError(result.message);
          }
        })
        .catch((err) => {
          modelUi.msgError(parseError(err));
        });
    },

    async getGroupNameListReq() {
      const params = {
        storeId: getStoreId(),
        storeQueryType: "",
        groupName: "",
        groupSize: "",
        strategyName: "",
        strategyType: "",
        itemCount: "",
        canvasSize: "",
        itemTag: "",
        templateId: "",
        // scenarioId: this.form.scenarioId || '',
        scenarioId: "",
        current: 1,
        size: 1000,
        orders: [],
      };
      const res = await this.$Api.layoutGetList(params);
      this.groupNameList = res.data || [];
      this.$forceUpdate();
    },
    async getScenarioList() {
      const { data } = await this.$Api.getNewScenarioList();
      this.sceneList = data || [];
    },
    // 获取模板类型列表
    getTemplateTypeListReq() {
      let storeId = getStoreId();
      console.log("-----this.form", this.form);
      if (this.form.templateId) {
        // 编辑数据
        storeId = this.form.storeId;
      }

      getTemplateTypeList({ storeId ,scenarioId:this.form.scenarioId})
        .then((res) => {
          const result = parseResp(res);
          if (result.success) {
            this.typeList = result.data;
          } else {
            modelUi.msgError(result.message);
          }
        })
        .catch((err) => {
          modelUi.msgError(parseError(err));
        });
    },

    deleteTemplateTypeReq(templateTypeId) {
      this.loading = true;
      deleteTemplateType(templateTypeId)
        .then((res) => {
          this.loading = false;
          const result = parseResp(res);
          if (result.success) {
            // modelUi.msgSuccess("删除成功");
            this.$message({
              message: this.$t("commonMessage.DeleteSucess"),
              type: "success",
              customClass: "messageIndex",
            });
            this.getTemplateTypeListReq();
          } else {
            modelUi.msgError(result.message);
          }
        })
        .catch((err) => {
          this.loading = false;
          modelUi.msgError(parseError(err));
        });
    },

    handleTemplaetTypeAdd() {
      let storeId = getStoreId();
      if (this.form.templateId) {
        // 编辑数据
        storeId = this.form.storeId;
      }
      this.$refs.addTemplateType.show("", "", storeId,this.form.scenarioId);
    },

    handleTemplateTypeEdit(row) {
      const { templateTypeId } = row;
      const { templateTypeName } = row;
      this.$refs.addTemplateType.show(templateTypeId, templateTypeName);
    },

    handleTemplateTypeDel(item) {
      const { templateTypeId } = item;
      this.deleteTemplateTypeReq(templateTypeId);
    },
    getColorListReq() {
      getCodeList(4)
        .then((res) => {
          const result = parseResp(res);
          if (result.success) {
            this.colorList = result.data;
          } else {
            modelUi.msgError(result.message);
          }
        })
        .catch((err) => {
          modelUi.msgError(parseError(err));
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.create-template {
  .el-select {
    width: 100%;
  }
  .creatInfaceTable .el-table__cell {
    border-right: 1px solid #ebeef5 !important;
  }

  .new_template_dialog-title {
    margin-left: 10px;
    font-size: 18px;
    font-family: Verdana;
    font-weight: 400;
  }

  .el-dialog__header {
    background-color: rgb(196, 238, 210);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .el-dialog__body {
    padding: 30px 20px !important;
  }
  .messageIndex {
    z-index: 3000 !important;
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
    ::v-deep .el-button {
      width: 110px;
    }
  }
  .el-collapse-item__header {
    height: 30px;
    line-height: 30px;
    background-color: #f3f3f3;
    border: none;
    border-radius: 10px;
    padding: 20px;
    font-size: 15px;
  }
  .el-collapse {
    border: none;
  }
  .el-collapse-item__content {
    padding: 15px 40px;
  }
  .dark-layout label {
    color: #71757c;
    font-size: 13px;
  }
  .el-form-item {
    margin-bottom: 20px;
  }
  .targetStore {
    height: 100px;
  }

  // 重置样式
  ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
}
.edit-template-type-item {
  display: flex;
  align-items: center;
  width: 350px;
  .el-icon-edit-outline,
  .el-icon-delete {
    padding: 6px;
    font-size: 18px;
  }
  .edit-template-type-name {
    color: #555555;
    font-size: 14px;
    flex: 1;
    overflow: hidden;
  }
}
</style>
