<template>
  <div class="common-table-list template-list">
    <div class="public-breadcrumb">
      <router-link style="color: #6e6b7b" to="/">{{
        $t("Welcome")
      }}</router-link>
      <span> > {{ $t('Layout Builder')}} > </span>
      <span style="color: #3461ff">{{ $t("Template") }}</span>
    </div>
    <topbar>
      <div class="template-list__top-btn">
        <div>
          <el-button type="primary" size="small" @click="createGroup">
            <i class="el-icon-plus" />
            {{ $t("button.New") }}
          </el-button>
          <el-button
            type="danger"
            size="small"
            :disabled="!selection()"
            @click="batchPhysicalDeleteTemplate"
          >
            <i class="el-icon-delete" />
            {{ $t("button.Batch Delete") }}
          </el-button>
        </div>
        <div>
          <el-button
            type="info"
            size="small"
            class="template-list__import"
            @click="handleImport"
            >{{ $t("button.Import") }}</el-button
          >
          <el-button
            type="info"
            size="small"
            class="template-list__import"
            :disabled="!selection()"
            @click="handleExport"
            >{{ $t("button.Export") }}</el-button
          >
          <!-- <el-button
            type="info"
            size="small"
            class="template-list__import"
            @click="handleShare"
          >Share To</el-button> -->
        </div>
      </div>
    </topbar>
    <div class="table_set card">
      <div class="select_filter">
        <div>
          <span class="text"
            >{{ $t("templateInfo.Records") }}: {{ pageParams.total }}</span
          >
          <!-- <el-select
            v-if="userType != '201' && userType != '202' && userType != '205'"
            v-model="pageParams.storeType"
            placeholder="请选择"
            @change="getTemplateListReq"
          >
            <el-option
              v-for="item in storeQueryTypeList"
              :key="item.dictValue"
              :label="item.dictName"
              :value="item.dictValue"
            />
          </el-select> -->
          <el-select
            v-if="userType != '201' && userType != '202' && userType != '205'"
            v-model="pageParams.storeType"
            placeholder=""
            size="small"
            @change="getTemplateListReq"
          >
            <el-option :label="$t('ThisStore')" :value="2" />
            <el-option :label="$t('ZimenStore')" :value="1" />
            <el-option :label="$t('AllStore')" :value="0" />
          </el-select>
        </div>
      </div>
      <!-- max-height="500" -->
      <el-table
        ref="templateTable"
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        row-key="id"
        @sort-change="handleSortChange"
        
      >
        <el-table-column type="selection" width="55" fixed>
          <el-table-column type="selection" />
        </el-table-column>
        <el-table-column :label="$t('templateInfo.Store Name')" min-width="100">
          <el-table-column prop="storeName" align="center" min-width="100">
            <template slot="header" slot-scope="scope">
              <el-input
                v-model="pageParams.storeName"
                size="mini"
                class="template-list_el_input"
                placeholder=""
                clearable
                @input="getTemplateListReq"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('templateInfo.Template Name')"
          sortable="custom"
          prop="templateName"
        >
          <el-table-column prop="templateName" align="center" min-width="160">
            <template slot="header" slot-scope="scope">
              <el-input
                v-model="pageParams.templateName"
                size="mini"
                class="template-list_el_input"
                placeholder=""
                clearable
                @input="getTemplateListReq"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('templateInfo.Code')"
          sortable="custom"
          prop="templateCode"
        >
          <el-table-column prop="templateCode" align="center" min-width="140">
            <template slot="header" slot-scope="scope">
              <el-input
                v-model="pageParams.templateCode"
                size="mini"
                class="template-list_el_input"
                placeholder=""
                clearable
                @input="getTemplateListReq"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('templateInfo.Type')">
          <el-table-column prop="templateTypeName" min-width="100px">
            <template slot="header" slot-scope="scope">
              <el-select
                v-model="pageParams.templateTypeId"
                class="template-list_el_select"
                size="mini"
                placeholder=""
                clearable
                @change="getTemplateListReq"
              >
                <el-option
                  v-for="dict in templateTypeList"
                  :key="dict.templateTypeId"
                  :label="dict.templateTypeName"
                  :value="dict.templateTypeId"
                />
              </el-select>
            </template>
            <template slot-scope="{row}">
              <div v-if="row.templateTypeName" class="template-type-name" :title="row.templateTypeName">{{row.templateTypeName}}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('templateInfo.Size')"
          sortable="custom"
          prop="templateSize"
        >
          <el-table-column prop="templateSize" min-width="100px">
            <template slot="header" slot-scope="scope">
              <el-input
                v-model="pageParams.templateType"
                size="mini"
                placeholder=""
                class="template-list_el_input"
                clearable
                @input="getTemplateListReq"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('templateInfo.Image')">
          <el-table-column prop="templateImageUrl" min-width="180">
            <template slot-scope="scope">
              <a
                v-if="scope.row.templateImageUrl"
                @click="imageLg(scope.row.templateImageUrl)"
              >
                <el-image
                  :src="scope.row.templateImageUrl"
                  style="width: 180px; height: 60px"
                />
              </a>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('templateInfo.Link Layout')">
          <el-table-column prop="linkLayout" min-width="140px">
            <!-- <template slot-scope="scope">
              <span>{{ scope.row.linkLayout }}</span>
            </template>-->
            <template slot-scope="{ row }">
              <span
                :title="$t('templateInfo.Related Details')"
                class="el-icon-document-copy layoutIcon"
                @click="handelDetail(row)"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('templateInfo.Scenarios')">
          <el-table-column prop="scenarioName" min-width="140">
            <template slot="header" slot-scope="scope">
              <el-select
                v-model="pageParams.scenarioId"
                class="template-list_el_select"
                size="mini"
                placeholder=""
                clearable
                @change="getTemplateListReq"
              >
                <el-option
                  v-for="dict in sceneList"
                  :key="dict.scenarioId"
                  :label="dict.translateTextName"
                  :value="dict.scenarioId"
                />
              </el-select>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('templateInfo.Update Time')"
          sortable="custom"
          prop="update_time"
        >
          <el-table-column prop="updateTime" min-width="206">
            <template slot="header">
              <el-date-picker
                v-model="pageParams.updateTime"
                type="daterange"
                align="center"
                size="small"
                unlink-panels
                range-separator="-"
                start-placeholder=""
                end-placeholder=""
                value-format="yyyy-MM-dd"
                class="intergation_picker"
                format="yyyy-MM-dd"
                @change="getTemplateListReq"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('templateInfo.Action')"
          width="130"
          fixed="right"
        >
          <el-table-column width="130">
            <template slot-scope="{ row }">
              <span class="table-icon"  @click="handleDelete(row)">
                <i class="el-icon-delete"></i>
              </span>
              <!-- <span class="icon" @click="handleEdit(row)" :title="$t('templateInfo.Edit Template')">
                <icon-svg name="edit" />
              </span> -->
              <span class="table-icon"  @click="handleEdit(row)">
                <i class="el-icon-edit-outline"></i>
              </span>
              <span class="icon" @click="toDoTemplate(row)" title="编辑模板">
                <icon-svg name="layout_itemp" />
              </span>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>

      <!-- 页码 -->
      <pagination :table-info="pageParams" @changePage="getTemplateListReq" />

    </div>
    <createTemplate
      ref="createTemplate"
      @add="handleAddTemplate"
      @update="handleUpdateTemplate"
    />

    <el-dialog :visible.sync="dialogImageVisible" :title="$t('layout.Preview')">
      <div style="text-align:center">
        <el-image
          :src="checkedSvgImgUrl"
        />
      </div>
    </el-dialog>

    <import-dialog ref="importDialogRef" @refresh="handleImportSuccess" />
    <export-dialog ref="exportDialogRef" />
    <template-edit-dialog ref="templateEditDialogRef" />
    <template-relation-detail-dialog ref="templateRelationDetailDialogRef" />

    <!-- 删除弹窗确认 -->
    <deleteConfirmDialog ref="deleteConfirmDialog" @onDeleteConfirm="_batchPhysicalDeleteTemplate"></deleteConfirmDialog>

  </div>
</template>

<script>
import createTemplate from "./dialog/createTemplate.vue";
import topbar from "@/components/ui-topbar/index.vue";
import pagination from '@/components/pagination';
import deleteConfirmDialog from '@/components/delete-confirm-dialog/index';

import {
  getStoreId,
  getUserType,
  getToken,
} from "@/views/taskManagement/taskManger/utils/cacheUtil.js";

import modelUi from "@/views/taskManagement/taskManger/utils/modelUi.js";

import importDialog from "@/views/layoutBuilder/templateList/dialog/importDialog.vue";
import exportDialog from "@/views/layoutBuilder/templateList/dialog/exportDialog.vue";
import templateEditDialog from "@/views/layoutBuilder/templateList/dialog/templateEditDialog.vue";
import templateRelationDetailDialog from "@/views/layoutBuilder/templateList/dialog/templateRelationDetailDialog.vue";
import {
  batchPhysicalDeleteTemplate,
  templateShare,
} from "@/libs/api/taskManger";
import {
  parseResp,
  parseError,
} from "@/views/taskManagement/taskManger/utils/requestUtil.js";

import {commonStore} from '@/store/global-select-store'

export default {
  components: {
    topbar,
    createTemplate,
    importDialog,
    exportDialog,
    templateEditDialog,
    templateRelationDetailDialog,
    pagination,
    deleteConfirmDialog
  },
  data() {
    return {
      commonStore,
      checkedSvgImgUrl: "",
      dialogImageVisible: false,
      userType: "",
      selected: "",
      search: "",
      pageParams: {
        templateName: "",
        templateTypeId: "",
        width: "",
        height: "",
        templateCode: "",
        scenarioId: "",
        updateTime: "",
        templateType: "",
        storeName: "",
        storeId: "",
        storeType: 2,
        current: 1,
        size: 10,
        total: 20,
        orders: [],
      },
      tableData: [],
      selectIds: [],
      columns: [],
      searchTerm: "",
      storeQueryTypeList: [
        {
          dictValue: 2,
          dictName: this.$t("ThisStore"),
        },
        {
          dictValue: 1,
          dictName: this.$t("ZimenStore"),
        },
        {
          dictValue: 0,
          dictName: this.$t("AllStore"),
        },
      ],
      sizeList: [
        { dictValue: 10, dictName: "10" },
        { dictValue: 20, dictName: "20" },
        { dictValue: 50, dictName: "50" },
        { dictValue: 100, dictName: "100" },
        { dictValue: 200, dictName: "200" },
        { dictValue: 500, dictName: "500" },
        { dictValue: 1000, dictName: "1000" },
      ],
      templateTypeList: [],
      sceneList: [],
    };
  },
  created() {
    commonStore.setInjectCallBack(this.initData)
    this.initData()
    this.getScenarioList();
    this.userType = getUserType();
  },
  mounted(){
    let { width,height,scenarioId } = this.$route.params
    console.log('this.$route.params',this.$route.params)
      if(width){
        let data = {
          templateSize:3,
          width,
          height,
          scenarioId
        }
        this.$refs.createTemplate.show(data);
      }
  },
  methods: {
    initData(){
     
      this.getTemplateListReq();
      this.getTypeList();
    },
    imageLg(url) {
      // window.open(url, "_blank");
      this.checkedSvgImgUrl = url;
      this.dialogImageVisible = true;
    },
    handleSortChange(v1) {
      const { prop } = v1;
      const { order } = v1;
      let orderStr = "";
      if (order === "descending") {
        orderStr = "desc";
      }
      if (order === "ascending") {
        orderStr = "asc";
      }

      let keyName = "";
      if (prop === "templateName") {
        keyName = "template_name";
      }
      if (prop === "templateCode") {
        keyName = "template_code";
      }
      if (prop === "templateSize") {
        keyName = "template_size";
      }
      if(prop === "update_time"){
        keyName = "update_time";
      }

      if (orderStr) {
        const orders = [];
        const orderItem = `${orderStr}:${keyName}`;
        orders.push(orderItem);
        this.pageParams.orders = orders;
      } else {
        this.pageParams.orders = [];
      }
      this.getTemplateListReq();
    },

    // 导入
    handleImport() {
      this.$refs.importDialogRef.openDialog();
    },

    // 导出
    handleExport() {
      const { selection } = this.$refs.templateTable;
      const templateIds = selection.map((it) => it.templateId);
      const templateNames = selection.map((it) => it.templateName);
      // if (templateIds.length === 0) {
      //   modelUi.msgError("请选择一条数据");
      // }  
      if(templateIds.length > 1) {
        this.$message.error(this.$t('templateInfo.ErrorMessage4'))
      } else {
        this.$refs.exportDialogRef.openDialog(templateNames[0], templateIds[0]);
      }
    },
    // 编辑模板
    toDoTemplate(row) {
      // 对接编辑器
      const { templateId } = row;
      const { scenarioId } = row;
      this.handleEnterSvgEdit(templateId, scenarioId);
    },
    // 分享
    async handleShare() {
      const { selection } = this.$refs.templateTable;
      const templateIds = selection.map((it) => it.templateId);
      // templateShare(this.formData)
      //   .then((res) => {
      //     const result = parseResp(res);
      //     if (result.success) {
      //       this.dialogVisible = false;
      //     } else {
      //       modelUi.msgError(result.message);
      //     }
      //   })
      //   .catch((err) => {
      //     modelUi.msgError(parseError(err));
      //   });
    },

    // 编辑
    handleEdit(row) {
      const newRow = JSON.parse(JSON.stringify(row));
      this.$refs.createTemplate.show(newRow);
    },

    // 详情
    handelDetail(row) {
      this.$refs.templateRelationDetailDialogRef.openDialog(row);
    },
    // batchPhysicalDeleteTemplate() {
    //   this.$confirm("确定要删除吗?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //     center: true,
    //   })
    //     .then(() => {
    //       const { selection } = this.$refs.templateTable;
    //       const templateIds = selection.map((it) => it.templateId);
    //       batchPhysicalDeleteTemplate({
    //         templateIds: templateIds.join(),
    //       })
    //         .then((res) => {
    //           const result = parseResp(res);
    //           if (result.success) {
    //             this.$message({
    //               message: this.$t("commonMessage.DeleteSucess"),
    //               type: "success",
    //             });
    //             this.getTemplateListReq();
    //           } else {
    //             modelUi.msgError(result.message);
    //           }
    //         })
    //         .catch((err) => {
    //           this.loading = false;
    //           modelUi.msgError(parseError(err));
    //         });
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: "info",
    //         message: "已取消删除",
    //       });
    //     });
    // },
    // 删除  ---单个删除
    handleDelete(row){
      let arr =[]
      arr.push(row.templateId)
      this.delTemplateIds = arr
      this.$refs.deleteConfirmDialog.open()
    },
    // 删除--批量删除
    batchPhysicalDeleteTemplate(){
      const { selection } = this.$refs.templateTable;
      this.delTemplateIds = selection.map((it) => it.templateId);
      this.$refs.deleteConfirmDialog.open();
    },
    async _batchPhysicalDeleteTemplate() {
      let ids =this.delTemplateIds
      try {
        let res = await this.$Api.batchPhysicalDeleteTemplate({
          templateIds: ids.join(),
        });
        if(res.state){
          this.$refs.deleteConfirmDialog.close();
          this.$message({
            type: "success",
            message: this.$t('commonMessage.DeleteSucess')
          });
          this.getTemplateListReq();
        } else {
          this.$message.error(res.message)
        }
        
      } catch (err){
        this.loading = false;
        this.$message.error(err.message)
      }

    },
    selection() {
      return (
        this.$refs.templateTable && this.$refs.templateTable.selection.length
      );
    },
    createGroup(row) {
      this.$refs.createTemplate.show();
    },
    async getScenarioList() {
      const { data } = await this.$Api.getNewScenarioList();
      this.sceneList = data || [];
    },
    async getTypeList() {
      const { data } = await this.$Api.getTypeList({storeId:getStoreId()});
      this.templateTypeList = data || [];
    },

    async getTemplateListReq() {
      const params = {
        templateName: this.pageParams.templateName,
        templateTypeId: this.pageParams.templateTypeId,
        width: "",
        height: "",
        storeId: getStoreId(),
        storeType: this.pageParams.storeType,
        templateCode: this.pageParams.templateCode,
        scenarioId: this.pageParams.scenarioId,
        updateTime: this.pageParams.updateTime
          ? `${this.pageParams.updateTime[0]},${this.pageParams.updateTime[1]}`
          : null,
        templateType: this.pageParams.templateType,
        storeName: this.pageParams.storeName,
        current: this.pageParams.current,
        size: this.pageParams.size,
        orders: this.pageParams.orders,
      };
      const res = await this.$Api.getTemplateList(params);
      const {
        data: { records, total },
      } = res;
      this.pageParams.total = total;
      this.tableData = records;
    },

    handleAddTemplate(templateId, scenarioId) {
      this.getTemplateListReq();
      this.handleEnterSvgEdit(templateId, scenarioId);
    },

    handleEnterSvgEdit(templateId, scenarioId) {
      const that = this;

      const { href } = this.$router.resolve({
        name: "template-svg-edit",
        query: { templateId, scenarioId },
      });
      window.open(href, "_blank");
      window.localStorage.setItem("refresh_template_list", false);
      window.onstorage = (e) => {
        const isRefreshPage = window.localStorage.getItem(
          "refresh_template_list"
        );
        if (isRefreshPage === "true") {
          window.localStorage.setItem("refresh_template_list", false);
          that.getTemplateListReq();
        }
      };
    },

    listenerCloseWindwo(params) {
      debugger;
    },

    handleUpdateTemplate() {
      this.getTemplateListReq();
    },

    handleImportSuccess() {
      this.pageParams.current = 1;
      this.getTemplateListReq();
    },
  },
};
</script>
<style lang="scss" scoped>
.template-list {
  .template-type-name{
    display: inline-block;
    background: #EFEFEF;
    border-radius: 14px;
    padding: 2px 16px;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  // .intergation_picker {
  //   width: 160px;
  // }
  .sizeQuery {
    width: 80px;
  }
  .layoutIcon {
    padding: 10px;
    border-radius: 50%;
    background-color: #dde3f6;
    font-weight: 800;
    cursor: pointer;
  }
  .template-list__top-btn {
    display: flex;
    justify-content: space-between;
    .el-button {
      border: none;
    }
  }
  .template-list__import {
    background: #eeeeee;
    border: 1px solid #eeeeee;
    font-family: Verdana;
    font-weight: 400;
    color: #666666;
    font-size: 14px;
    min-width: 80px;
  }

  .template-list_el_input {
    // width: 120px;
    width: 100%;
  }

  .template-list_el_select {
    // width: 120px;
    width: 100%;
  }

  .template-list_el_date_picker {
    width: 200px;
  }
  ::v-deep .el-image__inner {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

::v-deep .hideClass {
  visibility: hidden;
}


.table_set {
  padding: 15px 0;
  background-color: white;
  .select_filter {
    padding-bottom: 15px;
    .text {
      padding: 0 10px 15px 15px;
      font-size: 12px;
    }
    .table_label_size {
      width: 160px;
      font-size: 13px;
    }
    .table_select {
      width: 214px;
    }
  }
  .icon {
    padding: 6px;
    cursor: pointer;
    .svg-icon {
      width: 18px;
      height: 18px;
    }
  }
  .b_pag {
    padding: 0 35px;
  }
  .tb_checkbox {
    vertical-align: middle;
  }
}
.template-list ::v-deep {
  .el-table
    .el-table__fixed-header-wrapper
    .el-table__header
    .is-group
    tr:nth-of-type(2)
    th:nth-of-type(1)
    .cell {
      display: none;
    }
}
</style>
