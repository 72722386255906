<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :before-close="cancel"
    width="420px"
    class="template-list__export-dialog"
    style="padding: 10px"
  >
    <div slot="title">
      <span class="dialog-title">{{$t('button.Export')}}</span>
    </div>
    <div v-if="dialogVisible">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
      >
        <el-row
          type="flex"
          justify="center"
        >
          <el-col :span="12">
            <el-form-item>
              <div class="search-body">
                <div class="search-item">
                  <div class="result-status__checked" />
                  <span class="search-item__span">XML</span>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer__layout__2"
      >
        <el-button size="small" @click="handleCancel">{{$t('button.Cancel')}}</el-button>
        <el-button size="small" type="success"  @click="handleNext">{{$t('button.Submit')}}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { templateExport } from '@/libs/api/taskManger.js'
import modelUi from '@/views/taskManagement/taskManger/utils/modelUi.js'
import { parseResp, parseError } from '@/views/taskManagement/taskManger/utils/requestUtil.js'

export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      form: {},
      templateName: '',
      templateId: '',
      fileList: [],
      rules: {
        File: {
          required: true,
          message: 'Select to File',
          trigger: 'change',
        },
      },
    }
  },
  methods: {
    openDialog(templateName, templateId) {
      this.templateName = templateName
      this.templateId = templateId
      this.dialogVisible = true
    },
    cancel() {
      this.dialogVisible = false
    },
    fileChange() { },
    fileVisibleChange(val) {
      if (val && this.fileList.length === 0) { }
    },
    handleCancel() {
      this.dialogVisible = false
    },
    handleNext() {
      const params = {
        templateId: this.templateId,
      }
      templateExport(params)
        .then(res => {
          const { data } = res
          const downfile = new File([data], `${this.templateName}.xml`, {
            type: 'text/xml',
          })
          const tmpLink = document.createElement('a')
          const objectUrl = URL.createObjectURL(downfile)

          tmpLink.href = objectUrl
          tmpLink.download = downfile.name
          document.body.appendChild(tmpLink)
          tmpLink.click()

          document.body.removeChild(tmpLink)
          URL.revokeObjectURL(objectUrl)

          let status = res.status
          if(status === 200){
            this.dialogVisible = false
          } else {
            modelUi.msgError(res.data)
          }

          // const result = parseResp(res)
          // if (result.success) {
          //   this.dialogVisible = false
          // } else {
          //   modelUi.msgError(result.message)
          // }
        })
        .catch(err => {
          modelUi.msgError(parseError(err))
        })
    },
  },
}
</script>

<style lang="scss" scope>
.template-list__export-dialog {
  .el-dialog__header {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .el-dialog__body {
    padding: 30px 20px !important;
  }

  .dialog-footer__layout__2 {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    .el-button{
      width: 110px;
    }
  }

  .search-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .search-item {
    display: flex;
    align-items: center;
    height: 60px;
    margin: 0px 10px;
    margin-top: 30px;
  }

  .search-item__span {
    color: #555555;
    font-size: 16px;
  }

  .result-status__checked {
    width: 20px;
    height: 20px;
    background: #ffffff;
    border-radius: 13px;
    border: 5px solid #3461ff;
    margin: 0px;
    margin-right: 10px;
  }

  .button-cancel {
    background-color: #d1d1d1;
    border: 0px;
    color: #000;
    width: 120px;
    height: 50px;
    font-size: 18px;
    border-radius: 15px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .button-submit {
    background-color: #17b54d;
    border: 0px;
    color: #ffffff;
    width: 120px;
    height: 50px;
    font-size: 18px;
    border-radius: 15px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>
