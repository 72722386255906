<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :before-close="cancel"
    width="1020px"
    class="task-manger-task-history"
    style="padding: 10px"
  >
    <div slot="title">
      <span class="dialog-title">{{ $t("templateInfo.Related Details") }}</span>
    </div>
    <el-table
      v-if="dialogVisible"
      v-loading="loading"
      :data="tableData"
      style="width: 100%"
      class="table-topstyle"
      :header-cell-style="{ 'text-align': 'center' }"
      :cell-style="{ 'text-align': 'center' }"
    >
      <el-table-column
        :label="$t('templateInfo.Group Name')"
        align="center"
        prop="groupName"
      />
      <el-table-column
        :label="$t('templateInfo.Layout Strategy')"
        align="center"
        prop="strategyName"
      />
      <el-table-column
        :label="$t('templateInfo.Layout')"
        align="center"
        prop="layoutName"
      />
      <el-table-column
        :label="$t('templateInfo.Layout Block')"
        align="center"
        prop="lumpName"
      />
      <el-table-column
        :label="$t('templateInfo.Preview')"
        align="center"
        prop="layoutPreUrl"
      >
        <template slot-scope="scope">
          <el-image
            :src="scope.row.layoutPreUrl"
            style="width: 180px; height: 60px"
          />
        </template>
      </el-table-column>
    </el-table>

    <!-- 页码 -->
    <pagination
      :tableInfo="pageParams"
      @changePage="updateHistoryScheduleReq"
    />
  </el-dialog>
</template>

<script>
import pagination from "@/components/pagination";

import modelUi from "@/views/taskManagement/taskManger/utils/modelUi.js";

import { getTemplateLump } from "@/libs/api/taskManger.js";

export default {
  components: {
    pagination,
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      form: {},
      tableData: [],
      pageParams: {
        current: 1,
        size: 10,
        total: 10,
      },
      sizeList: [
        { dictValue: 10, dictName: "10" },
        { dictValue: 20, dictName: "20" },
        { dictValue: 50, dictName: "50" },
        { dictValue: 100, dictName: "100" },
        { dictValue: 200, dictName: "200" },
        { dictValue: 500, dictName: "500" },
        { dictValue: 1000, dictName: "1000" },
      ],
    };
  },
  methods: {
    openDialog(row) {
      this.form = row;
      this.dialogVisible = true;
      this.updateHistoryScheduleReq();
    },
    cancel() {
      this.dialogVisible = false;
    },
    updateHistoryScheduleReq() {
      const { templateId } = this.form;
      const { current, size } = this.pageParams;
      const orders = "";
      const params = {
        templateId: this.form.templateId,
        current: this.pageParams.current,
        size: this.pageParams.size,
        orders: [],
      };
      this.loading = true;
      getTemplateLump(params)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.data.records || [];
          this.pageParams.current = res.data.data.current;
          this.pageParams.total = res.data.data.total;
        })
        .catch((err) => {
          this.loading = false;
          modelUi.msgError(err.message);
        });
    },
  },
};
</script>

<style lang="scss" scope>
.task-manger-task-history {
  .dialog-title {
  }

  .el-dialog__header {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .el-dialog__body {
    padding: 30px 20px !important;
  }
}
</style>
