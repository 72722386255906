<template>
  <div class="template-import-dialog">
    <el-dialog
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      :before-close="cancel"
      width="600px"
      class="template-list__import-dialog"
      style="padding: 10px"
    >
      <div slot="title">
        <span class="dialog-title">{{$t('templateInfo.Import')}}</span>
      </div>
      <div v-if="dialogVisible">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          size="small"
        >
          <el-row
            type="flex"
            justify="center"
          >
            <el-col :span="12">
              <el-form-item>
                <div class="search-body">
                  <div class="search-item">
                    <div class="result-status__checked" />
                    <span class="search-item__span">XML</span>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="12">
              <el-form-item
                :label="$t('templateInfo.File')"
                prop="File"
                label-width="105px"
              >
                <el-upload
                  class="upload-demo"
                  action="none"
                  drag
                  :limit="1"
                  :auto-upload="false"
                  :on-change="setUploadData"
                  accept=".xml"
                >
                  <i class="el-icon-upload" />
                  <div class="el-upload__text">
                    {{$t('templateInfo.ImportTips')}}
                  </div>
                  <div
                    slot="tip"
                    class="el-upload__tip"
                  >
                  {{$t('templateInfo.ImportType')}}
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div
          slot="footer"
          class="dialog-footer__layout__2"
        >
          <el-button
            class="button-cancel"
            @click="handleCancel"
            size="small"
          >
            {{$t('button.Cancel')}}
          </el-button>
          <el-button
            class="button-next"
            @click="handleNext"
            size="small"
          >
          {{$t('button.Next')}}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { templateImport } from '@/libs/api/taskManger.js'
import modelUi from '@/views/taskManagement/taskManger/utils/modelUi.js'
import { parseResp, parseError } from '@/views/taskManagement/taskManger/utils/requestUtil.js'
// import de from '../svg-tool/src/editor/extensions/ext-storage/locale/de'

export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      form: {},
      uploadFile: null,
      formData: null,
      rules: {
        File: {
          required: true,
          message: 'Select to File',
          trigger: 'change',
        },
      },
    }
  },
  methods: {
    openDialog() {
      this.dialogVisible = true
    },
    cancel() {
      this.dialogVisible = false
    },
    fileChange() { },
    fileVisibleChange(val) {
      if (val && this.fileList.length === 0) { }
    },
    handleCancel() {
      this.dialogVisible = false
    },
    setUploadData(file) {
      this.uploadFile = file.raw
      const formData = new FormData()
      formData.append('file', this.uploadFile)
      this.formData = formData
    },
    handleNext() {
      templateImport(this.formData)
        .then(res => {
          const result = parseResp(res)
          if (result.success) {
            // modelUi.msgSuccess(this.$t('templateInfo.ImportSucess'))
            this.$message({
              message:this.$t('templateInfo.ImportSucess'),
              type:'success',
            });
            this.dialogVisible = false
            this.$emit('refresh')
          } else {
            this.$message.error(result.message)
            // modelUi.msgError(result.message)
          }
        })
        .catch(err => {
          this.$message.error(parseError(err))
          // modelUi.msgError(parseError(err))
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.template-import-dialog{
  .template-list__import-dialog {
    .el-dialog__header {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    .el-dialog__body {
      padding: 30px 20px !important;
    }

    .dialog-footer__layout__2 {
      display: flex;
      justify-content: center;
      margin-top: 50px;
    }

    .search-body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .search-item {
      display: flex;
      align-items: center;
      height: 60px;
      margin: 0px 10px;
    }

    .search-item__span {
      color: #555555;
      font-size: 16px;
    }

    .result-status__checked {
      width: 20px;
      height: 20px;
      background: #ffffff;
      border-radius: 13px;
      border: 5px solid #3461ff;
      margin: 0px;
      margin-right: 10px;
    }

    .button-cancel {
      background-color: #d1d1d1;
      border: 0px;
      color: #000;
      width: 120px;
      margin-left: 10px;
      margin-right: 10px;
    }

    .button-next {
      background-color: #17b54d;
      border: 0px;
      color: #ffffff;
      width: 120px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  // 重置样式
  ::v-deep .el-dialog{
    margin-top: 5vh!important;
  }
}
</style>
