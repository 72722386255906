<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :before-close="cancel"
    width="820px"
    class="template-list__import-dialog"
    style="padding: 10px"
  >
    <div slot="title">
      <span class="dialog-title">{{$t('templateInfo.Template details')}}</span>
    </div>
    <div v-if="dialogVisible">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="140px"
      >
        <el-row
          type="flex"
          justify="center"
        >
          <el-col :span="14">
            <el-form-item
              :label="$t('templateInfo.Scenarios')"
              prop="scenarios"
            >
              <el-select
                v-model="form.scenarios"
                class="form-item__select"
                :disabled="true"
              >
                <el-option
                  v-for="dict in scenariosList"
                  :key="dict.templateTypeId"
                  :label="dict.templateTypeName"
                  :value="dict.templateTypeId"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          justify="center"
        >
          <el-col :span="14">
            <el-form-item
              :label="$t('templateInfo.ESL Size')"
              prop="eslSize"
            >
              <el-input
                v-model="form.eslSize"
                class="form-item__select"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          justify="center"
        >
          <el-col :span="14">
            <el-form-item
              :label="$t('templateInfo.Canvas Width')"
              prop="canvasWidth"
            >
              <el-input
                v-model="form.canvasWidth"
                class="form-item__select"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          justify="center"
        >
          <el-col :span="14">
            <el-form-item
              :label="$t('templateInfo.Canvas Height')"
              prop="canvasHeight"
            >
              <el-input
                v-model="form.canvasHeight"
                class="form-item__select"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          justify="center"
        >
          <el-col :span="14">
            <el-form-item
              :label="$t('templateInfo.Template Type')"
              prop="templateType"
            >
              <el-select
                v-model="form.templateType"
                class="form-item__select"
                placeholder="Select to Template Type"
                @change="templateTypeChange"
                @visible-change="templateTypeVisibleChange"
              >
                <el-option
                  v-for="dict in templateTypeList"
                  :key="dict.templateTypeId"
                  :label="dict.templateTypeName"
                  :value="dict.templateTypeId"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          justify="center"
        >
          <el-col :span="14">
            <el-form-item
              :label="$t('templateInfo.Template Name')"
              prop="templateName"
            >
              <el-input
                v-model="form.templateName"
                class="form-item__select"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          justify="center"
        >
          <el-col :span="14">
            <el-form-item
              label="Template Code"
            >
              <el-input
                v-model="form.templateCode"
                class="form-item__select"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer__layout__2"
      >
        <button
          class="button-cancel"
          @click="handleCancel"
        >
          Cancel
        </button>
        <button
          class="button-save"
          @click="handleNext"
        >
          Save
        </button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import modelUi from '@/views/taskManagement/taskManger/utils/modelUi.js'

export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      form: {},
      scenariosList: [],
      templateTypeList: [],
      rules: {
        scenarios: {
          required: true,
          message: 'Select to Scenarios',
          trigger: 'change',
        },
        eslSize: {
          required: true,
          message: '',
          trigger: 'blur',
        },
        canvasWidth: {
          required: true,
          message: '',
          trigger: 'blur',
        },
        canvasHeight: {
          required: true,
          message: '',
          trigger: 'blur',
        },
        templateType: {
          required: true,
          message: 'Select to Template Type',
          trigger: 'change',
        },
        templateName: {
          required: true,
          message: 'Input Template Name',
          trigger: 'blur',
        },
        templateCode: {
          required: true,
          message: 'Input Template Code',
          trigger: 'blur',
        }
      },
    }
  },
  methods: {
    openDialog(row) {
      this.dialogVisible = true
      if(row){
        this.form= row;
      }
    },
    cancel() {
      this.dialogVisible = false
    },
    templateTypeChange() {},
    templateTypeVisibleChange(val) {
      if (val && this.templateTypeList.length === 0) {}
    },
    handleCancel() {
      this.dialogVisible = false
    },
    handleNext() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('refreshList')
          this.dialogVisible = false
        }
      })
    },
  },
}
</script>

<style lang="scss" scope>
.template-list__import-dialog {
  .el-dialog__header {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .el-dialog__body {
    padding: 30px 20px !important;
  }

  .dialog-footer__layout__2 {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  .search-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

   .form-item__select {
       width: 300px
   }

  .button-save {
    background-color: #17b54d;
    border: 0px;
    color: #ffffff;
    width: 120px;
    height: 50px;
    font-size: 18px;
    border-radius: 15px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>
